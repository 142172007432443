@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html,
    body {
        overflow: hidden;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    ul {
        list-style-type: disc;
        margin: 0px;
        padding: 4px 20px;
    }

    * {
        scrollbar-width: none;
    }

    @keyframes gradient {
        100% {
            background-position: 0% 50%;
        }
        75% {
            background-position: 40% 50%;
        }
        25% {
            background-position: 60% 50%;
        }
        0% {
            background-position: 100% 50%;
        }
    }

    @keyframes shake {
        0% {
            transform: translateX(0);
        }
        10%,
        30%,
        50%,
        70%,
        90% {
            transform: translateX(-10px);
        }
        20%,
        40%,
        60%,
        80% {
            transform: translateX(10px);
        }
        100% {
            transform: translateX(0);
        }
    }

    @keyframes tilt-shaking {
        0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(15deg);
        }
        50% {
            transform: rotate(0deg);
        }
        75% {
            transform: rotate(-15deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    .marquee {
        animation: marquee 60s linear infinite; /* Adjust the duration (60s in this example) */
    }

    @keyframes marquee {
        0% {
            transform: translateX(100%);
        } /* Start the animation from the right edge of the container */
        100% {
            transform: translateX(-100%);
        } /* Move the content to the left edge of the container */
    }
}

@layer utilities {
    *::-webkit-scrollbar {
        display: none;
    }

    .scrollbar-hide {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .scrollbar-hide::-webkit-scrollbar {
        display: none;
    }

    /* ... Other utility classes ... */

    /* Utility classes for gradients and more */
    .new-news-gradient {
        background: linear-gradient(
            90deg,
            #1c1f26 0%,
            #1d222c 20%,
            #1e2638 30%,
            #1f2b43 50%,
            #1e2638 70%,
            #1d222c 80%,
            #ffffef18 100%
        );
        background-size: 1000% 100%;
        animation: gradient 5s ease 1;
    }

    .important-news-gradient {
        background: linear-gradient(
            90deg,
            #1c1f26 0%,
            #1d2027 20%,
            #292324 30%,
            #3f2c21 50%,
            #292324 70%,
            #1d2027 80%,
            #1c1f26 100%
        );
        background-size: 500% 100%;
        animation: gradient 3s ease 5;
    }

    .read-important-news-gradiant {
        background: linear-gradient(
            90deg,
            /* #1c1f26 0%,
            #1d2027 20%, */ #292324 0%,
            #3e2b21 0%,
            #292324 20%,
            #1d2027 80%,
            #1c1f26 100%
        );
    }

    .play-feed-gradient {
        pointer-events: none;
        background: linear-gradient(180deg, #1c1f26 25%, rgba(28, 31, 38, 0) 80%);
    }

    .news-detail-gradient {
        pointer-events: none;
        background: linear-gradient(0deg, #1c1f26 25%, rgba(28, 31, 38, 0) 80%);
    }

    .toggle {
        color: #27af8f;
    }

    .toggle-27AF8F:checked,
    .toggle-27AF8F[checked="true"],
    .toggle-27AF8F[aria-checked="true"] {
        --tglbg: hsl(166 64% 42%);
        background-color: white;
        color: #ffffff !important;
    }

    .checkbox:checked {
        background-image:
            linear-gradient(-45deg, transparent 65%, #306ce8 65.99%),
            linear-gradient(45deg, transparent 75%, #306ce8 75.99%),
            linear-gradient(-45deg, #306ce8 40%, transparent 40.99%),
            linear-gradient(45deg, #306ce8 30%, #fff 30.99%, #fff 40%, transparent 40.99%),
            linear-gradient(-45deg, #fff 50%, #306ce8 50.99%);
    }

    .links-tooltip::before {
        width: 10rem;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
    }

    .input-disabled,
    .input[disabled] {
        cursor: not-allowed;
        --tw-border-opacity: 1;
        border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
        --tw-bg-opacity: 1;
        background-color: #252932 !important;
        --tw-text-opacity: 0.2;
    }

    /* ... More utility classes ... */
}

@layer components {
    /* Component styles */
    .feature-locked {
        overflow: hidden;
    }

    .feature-locked > * {
        user-select: none;
        pointer-events: none;
        --tw-grayscale: grayscale(0.5);
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale)
            var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
        --tw-blur: blur(2px);
    }

    .select {
        background-image: url(../../public/assets/SVG/GraySelect.svg) !important;
        background-size: 10px !important;
    }

    .chart-asset-select {
        .css-3h5y44-control {
            width: 100%;
        }

        .css-wx0v70-IndicatorsContainer {
            display: none;
        }

        .css-1jvk047 {
            display: none;
        }

        input {
            color: #505a68 !important;
            text-transform: uppercase;
        }

        [type="text"]:focus {
            --tw-ring-color: none !important;
        }

        .css-1vakgoa-control {
            box-shadow: none !important;
            width: 100%;
        }

        .css-5wls9v-singleValue {
            .check {
                display: none;
            }
            span {
                color: #505a68;
            }
        }
    }

    /* .css-1uw36zh-option:hover {
        filter: brightness(0.75);
        cursor: pointer;
    } */

    .filterset-select {
        .css-y5co6f-control {
            background-color: #252932;
        }

        .css-1we76l6-control {
            box-shadow: none !important;
        }

        .css-5wls9v-singleValue {
            span {
                display: none !important;
            }

            .check {
                display: none;
            }
        }
    }

    .radial-progress:after {
        background-color: #1c1f27 !important;
    }

    .horizontal-slider {
        width: 100%;
        max-width: none;

        .example-thumb {
            z-index: 100;
            background: #306ce8;
            border: 5px solid #306ce8;
            border-radius: 100%;
            display: flex;
            box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
            top: -7px;
            width: 12px;
            outline: none;
            height: 12px;
            line-height: 38px;
        }

        .example-track {
            height: 4px;
        }

        .example-track {
            position: relative;
            background: #3d4552;
            border-radius: 16px;
            top: -2.5px;
        }

        .track-unmute-1 {
            background-color: #3d4552 !important;
        }

        .track-unmute-0 {
            background-color: #306ce8 !important;
        }

        .track-mute {
            background: #3d4552 !important;
        }

        .volume-track {
            top: 18px !important;
        }

        .volume-thumb {
            top: 14px !important;
        }

        .thumb-mute {
            left: 0 !important;
            background: #3d4552 !important;
            border: 5px solid #3d4552;
        }
        /* .example-thumb.active {
            background-color: #306ce8;
        }

        .example-track.example-track-0 {
            background: #306ce8;
        }

        .volume-track-0 {
            background: #306ce8 !important;
        } */
    }

    .modal-radius-5 {
        border-radius: 0.5rem !important;
    }

    .modal-padding-0 {
        padding: 0 !important;
    }

    .modal-margin-0 {
        margin: 0 !important;
    }

    .modal-bg-20242c {
        background-color: #20242c !important;
    }

    .modal-bg-none {
        background: none !important;
    }

    .modal-padding-horizontal-2 {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }

    .modal-padding-vertical-point-2 {
        padding-top: 0.2rem !important;
        padding-bottom: 0.2rem !important;
    }

    .modal-container-right {
        text-align: right !important;
    }

    .alert-modal-root {
        top: 10% !important;
        left: 50% !important;
    }

    /* ... More component styles ... */

    /* Styles for the timepicker component */

    .filter-time-picker {
        .react-time-picker--disabled {
            background: none !important;
        }

        .react-time-picker {
            width: 100%;
            height: 100%;

            .react-time-picker__wrapper {
                border: none !important;
                border-radius: 8px !important;
                background-color: #20242c !important;

                .react-time-picker__inputGroup {
                    padding-left: 12px !important;

                    select {
                        text-transform: lowercase !important;
                        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width)
                            var(--tw-ring-offset-color);
                        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width))
                            var(--tw-ring-color);
                        box-shadow:
                            var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
                    }

                    .react-time-picker__inputGroup__input {
                        --tw-ring-color: none;
                    }
                }
            }
        }
    }

    .filter-date-picker {
        [type="text"]:focus {
            outline: none;
            --tw-ring-color: none;
            border-color: #2f343f;
        }

        .react-datepicker {
            border: 1px solid #2f343f;
        }

        .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
            border-bottom-color: #2f343f;
        }

        .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
            border-bottom-color: #252932;
        }

        .react-datepicker__month-container {
            background-color: #252932;
        }

        .react-datepicker__header {
            background-color: #252932;
            border-bottom: 1px solid #2f343f;

            .react-datepicker__current-month {
                color: white;
            }
            .react-datepicker__day-name {
                color: white;
            }
        }

        .react-datepicker__day {
            color: white;
        }

        .react-datepicker__day:hover {
            color: black;
        }

        .react-datepicker__day--keyboard-selected {
            color: black;
        }

        .react-datepicker__day--selected {
            color: black;
        }

        .react-datepicker__day--disabled {
            color: gray;
            :hover {
                color: gray;
            }
        }
        .react-datepicker__day--disabled:hover {
            color: gray;
        }
    }

    .important-h-80 {
        height: 20rem !important;
    }
}

/* Remaining styles that don't fit into the base, components, or utilities layers */
.news-gradient {
    background: linear-gradient(360deg, #1c1f26 16.92%, rgba(28, 31, 38, 0) 104.46%);
}

.horizontal-scroll-gradient {
    background: linear-gradient(270deg, #1c1f26 16.92%, rgba(28, 31, 38, 0) 104.46%);
}

.shadow-gradient {
    background: linear-gradient(180deg, rgba(28, 31, 38, 0) 0%, rgba(28, 31, 38, 0.8) 41.67%, #1c1f26 89.06%);
}

.user-shadow-gradient {
    background: linear-gradient(180deg, rgba(28, 31, 38, 0) 0%, rgba(28, 31, 38, 0.6) 41.67%, #1c1f26 100%);
}

/* ... More styles ... */

/* Remaining styles that don't fit into the base, components, or utilities layers */
.tooltip-custom-warning {
    --tooltip-color: #c83d4d !important;
    --tooltip-text-color: #ffffff !important;
}

.daily-sentiment {
    .tooltip:before {
        color: white !important;
        width: 14rem !important;
        background-color: #252932 !important;
    }
}

/* Remaining styles that don't fit into the base, components, or utilities layers */
.shake {
    animation: shake 0.4s ease-in-out;
}

.overflow-wrap {
    overflow-wrap: anywhere;
}
